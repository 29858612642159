import React from 'react';
import Layout from '../components/layout';
import {graphql, useStaticQuery, Link} from 'gatsby';
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import * as textCss from './modules/text.module.scss';
import * as expoCss from './modules/expo-passe.module.scss';

const ExpositionPage = () => {
    const datas = useStaticQuery(graphql`
        query {
            allContentfulExpositionsPasseUnique {
                edges {
                    node {
                        titre
                        sousTitre
                        contenuDeLaPage {raw}
                        toto {
                            urlDeLaPage
                            titre
                            dateDeLexposition
                            dateDeFinDeLexposition
                            imageMiseEnAvant {
                                resize {src}
                                file {url}
                                title
                            }   
                        }
                    }
                }
            }
        }
    `);

    const data = datas.allContentfulExpositionsPasseUnique.edges[0].node;
    const content = JSON.parse(data.contenuDeLaPage.raw);
    return (
        <Layout>
            <div className={textCss.container}>
                <h1>{data.titre}</h1>
                <h2>{data.sousTitre}</h2>
                <p>{documentToReactComponents(content)}</p>
                <ul className={expoCss.ul}>
                    {data.toto.map(edges => {
                        return (
                            <Link to={edges.urlDeLaPage}>
                                <li>
                                    {edges.imageMiseEnAvant ? <a href={edges.imageMiseEnAvant.file.url} target="_blank" rel="noreferrer"><img className={expoCss.img} src={edges.imageMiseEnAvant.resize.src} alt={edges.imageMiseEnAvant.title}/></a> : ''}
                                    <div>
                                        <h2>{edges.titre}</h2>
                                        <p>Du <b>{edges.dateDeLexposition}</b> au <b>{edges.dateDeFinDeLexposition}</b></p>
                                    </div>
                                </li>
                            </Link>
                        )
                    })}
                </ul>
            </div>
        </Layout>
    )
}

export default ExpositionPage;
